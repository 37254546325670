<template>
    <section v-if="status === 'added'" class="center" :class="theme">
        User '{{ form.invite }}' has been added to your TP Records account.
        <div class="form-footer">
            <el-button @click="close">OK</el-button>
        </div>
    </section>
    <section v-else-if="status === 'invited'" class="center" :class="theme">
        We sent {{ form.invite }} an invitation to join your TP Records account.
        <div class="form-footer">
            <el-button @click="close">OK</el-button>
        </div>
    </section>
    <section v-else :class="theme">
        <div class="info">
            <slot>
                To invite another TP Records user to monitor this account, enter
                their NDIS number.<br />
                For external users, enter their email address.
            </slot>
        </div>
        <el-form
            ref="form"
            class="invite-form"
            auto-complete="on"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="0px"
        >
            <el-form-item prop="invite">
                <el-input
                    ref="invite"
                    v-model="form.invite"
                    name="invite"
                    type="text"
                    auto-complete="on"
                    placeholder="NDIS number or email"
                />
            </el-form-item>
            <div class="form-footer">
                <el-button :disabled="loading" @click="handleInvite">
                    <i v-if="loading" class="el-icon-loading" />
                    <span v-else>Invite</span>
                </el-button>
            </div>
        </el-form>
    </section>
</template>

<script>
import * as Sentry from '@sentry/browser';
import auth from '@/utils/auth';
import {isValidEmail, validateNDISNumber} from '@/utils/validate';
import axios from 'axios';
import {API_CODES} from '@/utils/consts';

export default {
    name: 'client',
    components: {},
    props: {
        theme: {
            type: String,
            default: '',
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            form: {
                invite: null,
            },
            inviteError: null,
            rules: {
                invite: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: this.validateInvite,
                    },
                ],
            },
            status: null,
        };
    },
    computed: {},
    methods: {
        validateInvite(rule, value, callback) {
            // has invite been invalidated elsewhere in code?
            if (this.inviteError) {
                callback(this.inviteError);
                this.inviteError = null;
                return;
            }

            // is username an email address?
            if (!isValidEmail(value)) {
                // is username an NDIS number?
                if (!validateNDISNumber(value)) {
                    callback(new Error('Invalid Email or NDIS number'));
                    return;
                }

                // check if NDIS number matches this account
                if (
                    (this.user.role === undefined ||
                        this.user.role === auth.ROLE_USER) &&
                    value === this.user.ndis_number
                ) {
                    callback(
                        new Error(
                            "You can't invite yourself to monitor your own account"
                        )
                    );
                }
            }
            callback();
        },

        validateForm() {
            // turn the validation callback into a promise
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    resolve(valid);
                });
            });
        },

        async handleInvite() {
            const invite = this.form.invite;
            if (invite) {
                this.loading = true;
                const valid = await this.validateForm();
                if (valid) {
                    if (validateNDISNumber(invite)) {
                        // check if NDIS number matches a TPR user
                        let query = this.$fire
                            .collection('users')
                            .where('ndis_number', '==', invite)
                            .where('status', '==', 'active');
                        const snapshot = await query.get();
                        if (snapshot.size > 0) {
                            await this.addManagingUser(snapshot.docs[0]);
                            if (!this.inviteError) {
                                this.status = 'added';
                            }
                        } else {
                            this.inviteError =
                                'This NDIS number is not an active user of TP Records';
                            // force validate to display new error
                            this.validateForm();
                        }
                    } else if (isValidEmail(invite)) {
                        // check if email matches an existing external user
                        let query = this.$fire
                            .collection('users')
                            .where('email', '==', invite)
                            .where('role', '==', 3);
                        const snapshot = await query.get();
                        if (snapshot.size > 0) {
                            await this.addManagingUser(snapshot.docs[0]);
                            if (!this.inviteError) {
                                this.status = 'added';

                                // send a notification email
                                try {
                                    await axios.post(
                                        `${process.env.VUE_APP_BASE_URI}/user/notify-ext`,
                                        {email: invite}
                                    );
                                } catch (e) {
                                    this.$notify.error({
                                        title: 'Error inviting user',
                                        message:
                                            e.response?.data?.data?.message ??
                                            e.message,
                                    });
                                }
                            }
                        } else {
                            // no existing user, create one
                            try {
                                const result = await axios.post(
                                    `${process.env.VUE_APP_BASE_URI}/user/create-ext`,
                                    {email: invite}
                                );
                                const uid = result.data.data.uid;
                                let newUser = {
                                    email: invite,
                                    role: auth.ROLE_EXTERNAL,
                                    status: 'pending',
                                    is_temporary_password: true,
                                };
                                await this.$fire
                                    .doc(`users/${uid}`)
                                    .set(newUser);

                                // reload newly created user
                                const userSnapshot = await this.$fire
                                    .doc(`/users/${uid}`)
                                    .get();
                                await this.addManagingUser(userSnapshot);
                            } catch (e) {
                                this.inviteError = 'Error inviting user';
                                this.$notify.error({
                                    title: 'Error inviting user',
                                    message:
                                        e.response?.data?.data?.message ??
                                        e.message,
                                });
                            }

                            if (!this.inviteError) {
                                this.status = 'invited';
                            } else {
                                // force validate to display new error
                                this.validateForm();
                            }
                        }
                    }
                }
                this.loading = false;
            }
        },

        async addManagingUser(managingUserSnapshot) {
            const managingUserID = managingUserSnapshot.id;
            const managingUser = managingUserSnapshot.data();

            // add account user to managingUser's managedUsers list
            if (!managingUser.managedUsers) {
                managingUser.managedUsers = [];
            }
            if (
                !managingUser.managedUsers.find(
                    (user) => user.id === this.user.id
                )
            ) {
                managingUser.managedUsers.push({
                    name: this.user.name,
                    id: this.user.id,
                    notified: false,
                });
            }

            // add managingUser to account user's managedBy list
            if (!this.user.managedBy) {
                this.user.managedBy = [];
            }
            if (
                !this.user.managedBy.find((user) => user.id === managingUserID)
            ) {
                this.user.managedBy.push({
                    id: managingUserID,
                });
            }

            try {
                // perform both updates in one transaction
                await this.$fire.runTransaction(async (transaction) => {
                    await transaction.update(managingUserSnapshot.ref, {
                        managedUsers: managingUser.managedUsers,
                    });
                    const userRef = this.$fire.doc(`/users/${this.user.id}`);
                    await transaction.update(userRef, {
                        managedBy: this.user.managedBy,
                    });
                });
                this.$emit('invited');
            } catch (e) {
                this.inviteError = e.message;
                this.$notify.error({
                    title: 'Error',
                    message: 'Error inviting user',
                });
            }
        },

        close() {
            this.$emit('close');
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
section {
    background: white;
    padding: 50px;
    border-radius: 10px;
    &.center {
        text-align: center;
    }
    &.grey {
        background: $grey;
        padding: 30px;
        .info {
            font-size: 14px;
        }
    }
}
.invite-form {
    margin-top: 40px;
}
.form-footer {
    text-align: right;
}
.center .form-footer {
    text-align: center;
}
.el-button {
    color: white;
    background-color: $menuBg;

    &:hover {
        background-color: $menuHover;
    }
}
</style>
