var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-card" },
    [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "name" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
        ]),
      ]),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.action } },
        [_vm._v(_vm._s(_vm.button_label))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }