var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "linked-users", class: { admin: _vm.admin } },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.showInvite
          ? _c(
              "section",
              [
                _c(
                  "span",
                  { staticClass: "section-header" },
                  [
                    _c("h3", [_vm._v("Invite User")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.closeInvite },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "user-invite",
                  {
                    attrs: { user: _vm.user, theme: _vm.theme },
                    on: { invited: _vm.userInvited, close: _vm.closeInvite },
                  },
                  [
                    !_vm.admin
                      ? [
                          _vm._v(
                            " You can invite other users to monitor your TP Records account."
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            " To invite another TP Records user, enter their NDIS number."
                          ),
                          _c("br"),
                          _vm._v(
                            " For external users, enter their email address."
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(" You can remove their access at any time. "),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm.editingUser
          ? _c(
              "section",
              [
                _c(
                  "span",
                  { staticClass: "section-header" },
                  [
                    _c("h3", [_vm._v("Edit External User Profile")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.closeEditProfile },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ],
                  1
                ),
                _c("user-profile", {
                  attrs: {
                    "show-account-email": "",
                    admin: _vm.admin,
                    user: _vm.editingUser,
                  },
                  on: { saved: _vm.savedEditProfile },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  !_vm.isExternal
                    ? _c(
                        "section",
                        [
                          _c("div", { staticClass: "section-note" }, [
                            _vm._v(
                              " Users who are able to view " +
                                _vm._s(_vm.your) +
                                " plan & transactions "
                            ),
                          ]),
                          _c("add-card", {
                            attrs: {
                              title: "Add user?",
                              button_label: "Invite user",
                            },
                            on: { click: _vm.inviteUser },
                          }),
                          !_vm.loadingInvited &&
                          _vm.invitedUsers &&
                          _vm.invitedUsers.length
                            ? _vm._l(_vm.invitedUsers, function (usr) {
                                return _c("user-card", {
                                  key: usr.name,
                                  attrs: {
                                    user: usr,
                                    theme: _vm.theme,
                                    editable: _vm.admin,
                                    clickable: _vm.admin,
                                  },
                                  on: {
                                    remove: _vm.handleRemoveInvited,
                                    edit: _vm.handleEditProfile,
                                    click: _vm.handleClickUser,
                                  },
                                })
                              })
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingInvited,
                                      expression: "loadingInvited",
                                    },
                                  ],
                                  staticClass: "infobox",
                                  class: _vm.theme,
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.youHave) +
                                      " not invited any users to monitor " +
                                      _vm._s(_vm.admin ? "their" : "your") +
                                      " account. "
                                  ),
                                ]
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  _vm.managedUsers && _vm.managedUsers.length
                    ? _c(
                        "section",
                        [
                          _c("span", { staticClass: "section-header" }, [
                            _c("h3", [_vm._v("Managed Users")]),
                          ]),
                          _c("div", { staticClass: "section-note" }, [
                            _vm._v(
                              " Users who have invited " +
                                _vm._s(_vm.you) +
                                " to view their plan & transactions "
                            ),
                          ]),
                          !_vm.loadingManaged &&
                          _vm.managedUsers &&
                          _vm.managedUsers.length
                            ? [
                                _c("el-input", {
                                  attrs: {
                                    "suffix-icon": _vm.managedFilter
                                      ? ""
                                      : "el-icon-search",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.managedFilter,
                                    callback: function ($$v) {
                                      _vm.managedFilter = $$v
                                    },
                                    expression: "managedFilter",
                                  },
                                }),
                                _vm.managedFilter &&
                                _vm.filteredManagedUsers.length === 0
                                  ? _c("div", { staticClass: "no-results" }, [
                                      _vm._v(" No matches found "),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.filteredManagedUsers,
                                  function (usr) {
                                    return _c("user-card", {
                                      key: usr.name,
                                      attrs: {
                                        user: usr,
                                        theme: _vm.theme,
                                        editable: _vm.admin,
                                        clickable: true,
                                      },
                                      on: {
                                        remove: _vm.handleRemoveManaged,
                                        edit: _vm.handleEditProfile,
                                        click: _vm.handleClickUser,
                                      },
                                    })
                                  }
                                ),
                              ]
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingManaged,
                                      expression: "loadingManaged",
                                    },
                                  ],
                                  staticClass: "infobox",
                                  class: _vm.theme,
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.youAre) +
                                      " not currently managing any users. "
                                  ),
                                ]
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }