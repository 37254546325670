var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status === "added"
    ? _c("section", { staticClass: "center", class: _vm.theme }, [
        _vm._v(
          " User '" +
            _vm._s(_vm.form.invite) +
            "' has been added to your TP Records account. "
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [_c("el-button", { on: { click: _vm.close } }, [_vm._v("OK")])],
          1
        ),
      ])
    : _vm.status === "invited"
    ? _c("section", { staticClass: "center", class: _vm.theme }, [
        _vm._v(
          " We sent " +
            _vm._s(_vm.form.invite) +
            " an invitation to join your TP Records account. "
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [_c("el-button", { on: { click: _vm.close } }, [_vm._v("OK")])],
          1
        ),
      ])
    : _c(
        "section",
        { class: _vm.theme },
        [
          _c(
            "div",
            { staticClass: "info" },
            [
              _vm._t("default", function () {
                return [
                  _vm._v(
                    " To invite another TP Records user to monitor this account, enter their NDIS number."
                  ),
                  _c("br"),
                  _vm._v(" For external users, enter their email address. "),
                ]
              }),
            ],
            2
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "invite-form",
              attrs: {
                "auto-complete": "on",
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "0px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "invite" } },
                [
                  _c("el-input", {
                    ref: "invite",
                    attrs: {
                      name: "invite",
                      type: "text",
                      "auto-complete": "on",
                      placeholder: "NDIS number or email",
                    },
                    model: {
                      value: _vm.form.invite,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "invite", $$v)
                      },
                      expression: "form.invite",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.handleInvite },
                    },
                    [
                      _vm.loading
                        ? _c("i", { staticClass: "el-icon-loading" })
                        : _c("span", [_vm._v("Invite")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }