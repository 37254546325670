<template>
    <div class="linked-users" :class="{admin: admin}">
        <transition name="fade" mode="out-in">
            <section v-if="showInvite">
                <span class="section-header">
                    <h3>Invite User</h3>
                    <el-button size="mini" @click="closeInvite">
                        Close
                    </el-button>
                </span>
                <user-invite
                    :user="user"
                    :theme="theme"
                    @invited="userInvited"
                    @close="closeInvite"
                >
                    <template v-if="!admin">
                        You can invite other users to monitor your TP Records
                        account.<br /><br />
                        To invite another TP Records user, enter their NDIS
                        number.<br />
                        For external users, enter their email address.<br /><br />
                        You can remove their access at any time.
                    </template>
                </user-invite>
            </section>
            <section v-else-if="editingUser">
                <span class="section-header">
                    <h3>Edit External User Profile</h3>
                    <el-button size="mini" @click="closeEditProfile">
                        Close
                    </el-button>
                </span>
                <user-profile
                    show-account-email
                    :admin="admin"
                    :user="editingUser"
                    @saved="savedEditProfile"
                />
            </section>
            <div v-else>
                <transition name="fade" mode="out-in">
                    <section v-if="!isExternal">
                        <div class="section-note">
                            Users who are able to view {{ your }} plan &amp;
                            transactions
                        </div>

                        <add-card
                            title="Add user?"
                            button_label="Invite user"
                            @click="inviteUser"
                        />

                        <template
                            v-if="
                                !loadingInvited &&
                                invitedUsers &&
                                invitedUsers.length
                            "
                        >
                            <user-card
                                v-for="usr in invitedUsers"
                                :key="usr.name"
                                :user="usr"
                                :theme="theme"
                                :editable="admin"
                                :clickable="admin"
                                @remove="handleRemoveInvited"
                                @edit="handleEditProfile"
                                @click="handleClickUser"
                            />
                        </template>
                        <div
                            v-else
                            v-loading="loadingInvited"
                            class="infobox"
                            :class="theme"
                        >
                            {{ youHave }} not invited any users to monitor
                            {{ admin ? 'their' : 'your' }} account.
                        </div>
                    </section>
                </transition>
                <transition name="fade" mode="out-in">
                    <section v-if="managedUsers && managedUsers.length">
                        <span class="section-header">
                            <h3>Managed Users</h3>
                        </span>
                        <div class="section-note">
                            Users who have invited {{ you }} to view their plan
                            &amp; transactions
                        </div>
                        <template
                            v-if="
                                !loadingManaged &&
                                managedUsers &&
                                managedUsers.length
                            "
                        >
                            <el-input
                                v-model="managedFilter"
                                :suffix-icon="
                                    managedFilter ? '' : 'el-icon-search'
                                "
                                clearable
                            ></el-input>
                            <div
                                v-if="
                                    managedFilter &&
                                    filteredManagedUsers.length === 0
                                "
                                class="no-results"
                            >
                                No matches found
                            </div>
                            <user-card
                                v-for="usr in filteredManagedUsers"
                                :key="usr.name"
                                :user="usr"
                                :theme="theme"
                                :editable="admin"
                                :clickable="true"
                                @remove="handleRemoveManaged"
                                @edit="handleEditProfile"
                                @click="handleClickUser"
                            />
                        </template>
                        <div
                            v-else
                            v-loading="loadingManaged"
                            class="infobox"
                            :class="theme"
                        >
                            {{ youAre }} not currently managing any users.
                        </div>
                    </section>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import auth from '@/utils/auth';
import UserCard from './UserCard.vue';
import UserInvite from './UserInvite.vue';
import UserProfile from './UserProfile.vue';
import AddCard from '../components/AddCard.vue';

export default {
    name: 'client',
    components: {
        AddCard,
        UserCard,
        UserInvite,
        UserProfile,
    },
    props: {
        admin: {
            type: Boolean,
        },
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loadingInvited: false,
            loadingManaged: false,
            invitedUsers: [],
            managedUsers: [],
            showInvite: false,
            editingUser: null,
            managedFilter: '',
        };
    },
    computed: {
        filteredManagedUsers() {
            if (this.managedFilter) {
                return this.managedUsers.filter((u) =>
                    u.name
                        .toLowerCase()
                        .includes(this.managedFilter.toLowerCase())
                );
            }
            return this.managedUsers;
        },
        isExternal() {
            return this.user.role === auth.ROLE_EXTERNAL;
        },
        // convenience values for different strings when admin or not
        youHave() {
            if (this.admin) return 'This user has';
            return 'You have';
        },
        youAre() {
            if (this.admin) return 'This user is';
            return 'You are';
        },
        your() {
            if (this.admin) return "this account's";
            return 'your';
        },
        you() {
            if (this.admin) return 'this account';
            return 'you';
        },

        theme() {
            if (this.admin) return 'grey';
            return 'white';
        },
    },
    mounted() {
        this.getInvitedUsers();
        this.getManagedUsers();
    },
    methods: {
        inviteUser() {
            this.showInvite = true;
        },
        userInvited() {
            this.getInvitedUsers();
        },
        closeInvite(reload) {
            this.showInvite = false;
        },
        async getInvitedUsers() {
            if (this.user.managedBy) {
                this.loadingInvited = true;
                this.invitedUsers = [];

                for (const u of this.user.managedBy) {
                    const userSnapshot = await this.$fire
                        .doc(`users/${u.id}`)
                        .get();
                    const user = userSnapshot.data();
                    user.id = userSnapshot.id;
                    this.invitedUsers.push(user);
                }

                this.loadingInvited = false;
            }
        },

        async getManagedUsers() {
            if (this.user.managedUsers) {
                this.loadingManaged = true;
                this.managedUsers = [];

                for (const u of this.user.managedUsers) {
                    const userSnapshot = await this.$fire
                        .doc(`users/${u.id}`)
                        .get();
                    const user = userSnapshot.data();
                    user.id = userSnapshot.id;
                    this.managedUsers.push(user);
                }

                this.loadingManaged = false;
            }
        },

        handleRemoveInvited(invitedId) {
            this.$confirm(
                `Revoke this user's permission to monitor ${
                    this.admin ? 'this' : 'your'
                } account?`,
                'Remove user'
            )
                .then(() => {
                    this.loadingInvited = true;
                    if (this.removeUser(invitedId, this.user.id)) {
                        // remove user from invited list
                        const index = this.user.managedBy.findIndex(
                            (u) => u.id === invitedId
                        );
                        if (index >= 0) {
                            this.user.managedBy.splice(index, 1);
                        }
                        this.getInvitedUsers();
                    }
                })
                .catch(() => {
                    this.loadingInvited = false;
                });
        },

        handleRemoveManaged(managedId) {
            this.$confirm("Stop managing this user's account?", 'Remove user')
                .then(() => {
                    this.loadingManaged = true;
                    if (this.removeUser(this.user.id, managedId)) {
                        // remove user from managed list
                        const index = this.user.managedUsers.findIndex(
                            (u) => u.id === managedId
                        );
                        if (index >= 0) {
                            this.user.managedUsers.splice(index, 1);
                            this.$bus.$emit('refreshAuth', {});
                        }
                        this.getManagedUsers();
                    }
                })
                .catch(() => {
                    this.loadingManaged = false;
                });
        },

        handleEditProfile(user) {
            this.editingUser = user;
        },

        closeEditProfile() {
            this.editingUser = false;
        },

        savedEditProfile() {
            this.getInvitedUsers();
            this.getManagedUsers();
            this.closeEditProfile();
        },

        async removeUser(managingUserID, managedUserID) {
            const managingUserSnapshot = await this.$fire
                .doc(`/users/${managingUserID}`)
                .get();
            const managedUserSnapshot = await this.$fire
                .doc(`/users/${managedUserID}`)
                .get();

            let managingUser = managingUserSnapshot.data();
            let managedUser = managedUserSnapshot.data();

            // remove managedUser's ID from managingUser's managedUsers list
            if (managingUser.managedUsers) {
                managingUser.managedUsers = managingUser.managedUsers.filter(
                    (u) => u.id != managedUserSnapshot.id
                );
            }

            // remove managingUser's ID from managedUser's managedBy list
            if (managedUser.managedBy) {
                managedUser.managedBy = managedUser.managedBy.filter(
                    (u) => u.id != managingUserSnapshot.id
                );
            }

            try {
                // perform both updates in one transaction
                await this.$fire.runTransaction(async (transaction) => {
                    await transaction.update(managingUserSnapshot.ref, {
                        managedUsers: managingUser.managedUsers,
                    });
                    await transaction.update(managedUserSnapshot.ref, {
                        managedBy: managedUser.managedBy,
                    });
                });
                return true;
            } catch (e) {
                console.log(e);
                this.$notify.error({
                    title: 'Error',
                    message: 'Error removing user',
                });
                return false;
            }
        },

        handleClickUser(user) {
            if (this.admin) {
                this.$router.push(`/user/${user.id}`);
            } else {
                this.$router.push(`/client/${user.id}`);
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.linked-users {
    section {
        margin-bottom: 40px;

        .section-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .section-note {
            margin-bottom: 30px;
            font-size: 13px;
            color: #606266;
        }
    }

    &.admin section {
        margin-bottom: 20px;
    }

    .infobox {
        background: white;
        padding: 50px;
        border-radius: 10px;
        text-align: center;
        margin-bottom: 20px;

        &.grey {
            background: $grey;
            padding: 30px;
        }
    }

    .invite-form {
        margin-top: 40px;

        .form-footer {
            text-align: right;
            margin-top: 40px;
        }
    }

    .no-results {
        text-align: center;
        font-size: 13px;
        padding: 10px 0;
    }
}
</style>
