<template>
    <div
        class="user-card"
        :class="[theme, {clickable: clickable && isUser}]"
        @click="clickUserCard"
    >
        <div class="col">
            <div class="name" :class="{pending: isPending}">
                <span v-if="isPending">PENDING</span>
                <span v-else>{{ user.name }}</span>
            </div>
            <div class="identifier">
                <span v-if="isExternal">{{ displayEmail }}</span>
                <span v-else>{{ user.ndis_number }}</span>
            </div>
        </div>
        <template v-if="editable">
            <el-button
                v-if="isExternal"
                class="edit"
                icon="el-icon-edit"
                @click.stop="editUser"
            />
        </template>
        <el-button
            class="remove"
            icon="el-icon-close"
            @click.stop="removeUser"
        />
    </div>
</template>

<script>
import auth from '@/utils/auth';
export default {
    name: 'user-card',
    props: {
        user: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: '',
        },
    },
    computed: {
        isPending() {
            return this.user.status === 'pending';
        },
        isUser() {
            return (
                this.user.role === undefined ||
                this.user.role === auth.ROLE_USER ||
                this.user.role === 'user'
            );
        },
        isExternal() {
            return this.user.role === auth.ROLE_EXTERNAL;
        },
        displayEmail() {
            if (this.user.contact_email) {
                return this.user.contact_email;
            }
            return this.user.email;
        },
    },
    methods: {
        clickUserCard() {
            if (this.clickable && this.isUser) {
                this.$emit('click', this.user);
            }
        },
        removeUser() {
            this.$emit('remove', this.user.id);
        },
        editUser() {
            this.$emit('edit', this.user);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-card {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    transition: background-color 0.25s;

    &.grey {
        background: $grey;
    }

    .col {
        display: flex;
        flex: 1;
        flex-direction: column;

        .name {
            font-size: 16px;
            font-weight: bold;

            &.pending {
                opacity: 0.25;
            }
        }

        .identifier {
            flex: 1;
            margin-top: 5px;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .el-button {
        padding: 12px;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background-color: #fafafa;
            outline: 1px solid $grey;
        }
    }
}
</style>
