<template>
    <div class="user-card">
        <div class="col">
            <div class="name">
                <span>{{ title }}</span>
            </div>
        </div>
        <el-button type="primary" @click="action">{{ button_label }}</el-button>
    </div>
</template>

<script>
import auth from '@/utils/auth';
export default {
    name: 'add-card',
    props: {
        title: {
            type: String,
            required: true,
        },
        button_label: {
            type: String,
            required: true,
        },
    },
    methods: {
        action() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.user-card {
    background: $grey;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    transition: background-color 0.25s;

    .col {
        display: flex;
        flex: 1;
        flex-direction: column;

        .name {
            font-size: 16px;
            font-weight: bold;

            &.pending {
                opacity: 0.25;
            }
        }
    }

    .el-button {
        padding: 12px;
    }
}
</style>
