var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-card",
      class: [_vm.theme, { clickable: _vm.clickable && _vm.isUser }],
      on: { click: _vm.clickUserCard },
    },
    [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "name", class: { pending: _vm.isPending } }, [
          _vm.isPending
            ? _c("span", [_vm._v("PENDING")])
            : _c("span", [_vm._v(_vm._s(_vm.user.name))]),
        ]),
        _c("div", { staticClass: "identifier" }, [
          _vm.isExternal
            ? _c("span", [_vm._v(_vm._s(_vm.displayEmail))])
            : _c("span", [_vm._v(_vm._s(_vm.user.ndis_number))]),
        ]),
      ]),
      _vm.editable
        ? [
            _vm.isExternal
              ? _c("el-button", {
                  staticClass: "edit",
                  attrs: { icon: "el-icon-edit" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.editUser.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("el-button", {
        staticClass: "remove",
        attrs: { icon: "el-icon-close" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.removeUser.apply(null, arguments)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }